import React from 'react'
import { useNavigate } from 'react-router'
import { SECOND_SERVER } from '../../data/constants'
import useWindowDimensions from '../../context/windowDimensions'

const Girallon = () => {
  const {width} = useWindowDimensions()
  const navigate = useNavigate()

  return (
    <div className='map-container'>
      <div className='map-content'>
        <div className='map-header'>
          <button onClick={() => { navigate(-1) }}>Back</button>
          <h2>Seed: </h2>
          <div></div>
        </div>
        <iframe style={{ width: `${width - 360}px`, height: `${(width - 360) / 16.0 * 9.0}px` }} title={SECOND_SERVER} src='https://minecraft.flynns.space/girallon/index.html' />
      </div>
    </div>
  )
}

export default Girallon
