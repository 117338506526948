import React, { useState } from 'react'
import { stack as Menu } from 'react-burger-menu'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import { MINECRAFT_VIEW, DND_VIEW, INDEX_VIEW, DND_PATH, MINECRAFT_PATH } from '../data/constants'

// TODO: Don't do this, yikes
var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px',
  },
  bmBurgerBars: {
    background: 'aliceblue',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    textAlign: 'left',
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    color: 'white',
    margin: '1rem 0',
    textDecoration: 'none',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  linkButton: {
    backgroundColor: 'unset',
    border: 'unset',
    fontSize: 'larger',
    cursor: 'pointer'
  }
}


const Navigation = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  styles.selectedLinkButton = {
    ...styles.linkButton,
    borderBottom: '1px solid white'
  }

  const goForth = (view) => {
    setOpen(false)
    // TODO: Why doesn't this work?
  }

  return (
    <Menu styles={styles} isOpen={open}>
      <NavLink styles={styles.linkButton} to={MINECRAFT_PATH} onClick={goForth}>{MINECRAFT_VIEW}</NavLink>
      <NavLink styles={styles.linkButton} to={DND_PATH} onClick={goForth}>{DND_VIEW}</NavLink>
      <NavLink styles={styles.linkButton} to='/' onClick={goForth}>{INDEX_VIEW}</NavLink>
    </Menu>
  )
}

export default Navigation
/*
<button style={view === MINECRAFT_VIEW ? styles.selectedLinkButton : styles.linkButton} onClick={() => { navigate(MINECRAFT_PATH) }}>{MINECRAFT_VIEW}</button>
      <button style={view === DND_VIEW ? styles.selectedLinkButton : styles.linkButton} onClick={() => { navigate(DND_PATH) }}>{DND_VIEW}</button>
      <button style={view === INDEX_VIEW ? styles.selectedLinkButton : styles.linkButton} onClick={() => { navigate('/') }}>{INDEX_VIEW}</button>
*/