import { hydrateTable } from './helper'

const dwarfTable = {
  40: { name: 'Hill', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: undefined },
  41: { name: 'Aquatic', str: 2, dex: -4, con: 2, ac: 0, lvl: 0, notes: 'Waterbreathing, Swim Speed' },
  44: { name: 'Arctic', str: 2, dex: -4, con: 2, ac: 0, lvl: 0, notes: 'Cold Endurance' },
  49: { name: 'Badlands', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Waterwise, Heat Endurance, Camel' },
  64: { name: 'Deep', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Light Sensitive' },
  67: { name: 'Desert', str: 0, dex: -2, con: 2, ac: 0, lvl: 0, notes: 'Heat Endurance, uncunning' },
  70: { name: 'Dream', str: 0, dex: -2, con: 2, ac: 0, lvl: 0, notes: 'Dream Sight, +1 CL on Diviniation Spells' },
  71: { name: 'Frost', str: 0, dex: 0, con: 4, ac: 0, lvl: 1, notes: 'CE, Cold Resist 10' },
  74: { name: 'Earth', str: 2, dex: -2, con: 2, ac: 0, lvl: 0, notes: 'Stabler, Cunninger, Bad at Air' },
  77: { name: 'Glacier', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Cold Endurance, Ice Cunning' },
  92: { name: 'Gold', str: 0, dex: -2, con: 2, ac: 0, lvl: 0, notes: 'Aberration racism' },
  93: { name: 'Gray', str: 0, dex: 0, con: 2, ac: 0, lvl: 1, notes: 'Lol Duergar immunities' },
  96: { name: 'Jungle', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Random skill bonuses' },
  99: { name: 'Seacliff', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Better at swimming' },
  100: { name: 'Roll 2 and Pick' }
}

const elfTable = {
  40: { name: 'High', str: 0, dex: 2, con: -2, ac: 0, lvl: 0, notes: undefined },
  54: { name: 'Aquatic', str: 0, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Auqatic Weapon prof, Water Breathe, Swim Speed' },
  58: { name: 'Arctic', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Cold Endurance, Skill Bumps' },
  59: { name: 'Dark', str: 0, dex: 2, con: -2, ac: 0, lvl: 2, notes: 'SR lvl+11, Weird Proficiencies, Tortured outcast of society' },
  63: { name: 'Desert', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Different proficiencies' },
  67: { name: 'Fire', str: 0, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Fire Resist 5' },
  71: { name: 'Gray', str: -2, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Smort' },
  75: { name: 'Jungle', str: 0, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Different Skills/Proficiencies' },
  77: { name: 'Painted', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Purty' },
  79: { name: 'Snow', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Cold' },
  89: { name: 'Wild', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Dumb' },
  99: { name: 'Wood', str: 2, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Stronk' },
  100: { name: 'Roll 2 and Pick' }
}

const gnollTable = {
  90: { name: 'Plains', str: 4, dex: 0, con: 2, ac: 1, lvl: 0, notes: undefined },
  100: { name: 'Flind', str: 6, dex: 2, con: 4, ac: 2, lvl: 2, notes: 'Bigger Badder Boi' }
}

const gnomeTable = {
  40: { name: 'Rock', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Default' },
  44: { name: 'Air', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Breathless, Earth Sucks' },
  48: { name: 'Aquatic', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Breathe Water, Swim, Well Traveled' },
  53: { name: 'Arctic', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Cold Endurance' },
  58: { name: 'Chaos', str: -2, dex: 2, con: 2, ac: 0, lvl: 1, notes: 'Reroll anything, Confusion Immune, Better Spells' },
  63: { name: 'Desert', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Heat Endurance, Skill Bumps' },
  67: { name: 'Fire', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Stonecunning, Firey Bois, Fire Resist 5' },
  77: { name: 'Forest', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Hidier' },
  81: { name: 'Ice', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Ice Cold' },
  85: { name: 'Jungle', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Different Skills' },
  89: { name: 'Wavecrest', str: -2, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Screw bad fish bois' },
  99: { name: 'Whisper', str: -2, dex: 2, con: 2, ac: 0, lvl: 0, notes: 'Darkvision, Different Spells' },
  100: { name: 'Roll 2 and pick' }
}

const goblinoidTable = {
  15: { name: 'Bugbear', str: 4, dex: 2, con: 2, ac: 3, lvl: 1, notes: 'Darkvision' },
  46: { name: 'Goblin', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: undefined },
  50: { name: 'Air Goblin', str: -2, dex: 4, con: -2, ac: 0, lvl: 0, notes: 'Breathless' },
  53: { name: 'Aquatic Goblin', str: -2, dex: 2, con: 2, ac: 0, lvl: 0, notes: 'Breathe Water, Swim' },
  56: { name: 'Arctic Goblin', str: -2, dex: 2, con: 2, ac: 0, lvl: 0, notes: 'Cold Endurance, Shifty' },
  60: { name: 'Bhuka Goblin', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Medium?!?!, Heat Endurance, Love Sand, Sense Water' },
  64: { name: 'Desert Goblin', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Heat Endurance' },
  68: { name: 'Forestkith Goblin', str: 0, dex: 2, con: 0, ac: 1, lvl: 0, notes: 'Move Goodly' },
  73: { name: 'Jungle Goblin', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Climb Speed' },
  76: { name: 'Snow Goblin', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Intimidating, Climb Speed' },
  89: { name: 'Hobgoblin', str: 0, dex: 2, con: 2, ac: 0, lvl: 0, notes: '+1 LA if you hate your players' },
  91: { name: 'Fire Hobgoblin', str: 0, dex: 2, con: 2, ac: 0, lvl: 1, notes: 'Fire Resist 5' },
  94: { name: 'Norker', str: 0, dex: 2, con: 2, ac: 5, lvl: 1, notes: 'Weirdos from Dragon Magazine' },
  99: { name: 'Varag', str: 4, dex: 4, con: 2, ac: 3, lvl: 2, notes: 'Fast, Furious' },
  100: { name: 'Roll 2 and pick' }
}

const halfElfTable = {
  100: { name: '', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Diplomacy, Flexible Heritage' }
}

const halfOrcTable = {
  100: { name: '', str: 2, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Angry, Flexible Heritage' }
}

const halflingTable = {
  40: { name: 'Lightfoot', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: undefined },
  45: { name: 'Aquatic', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Breathe Water, Swim Speed' },
  50: { name: 'Arctic', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Cold Endurance' },
  60: { name: 'Deep', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Darkvision, Stonecunning' },
  65: { name: 'Desert', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Heat Endurance' },
  70: { name: 'Jungle', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Poison Use, Proficiencies' },
  80: { name: 'Shoal', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Amphibious, Swim Speed' },
  90: { name: 'Tallfellow', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Findy' },
  95: { name: 'Tundra', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Slightly Different Skills' },
  99: { name: 'Water', str: -2, dex: 2, con: 2, ac: 0, lvl: 0, notes: 'Swim Speed' },
  100: { name: 'Roll 2 and pick' }
}

const humanTable = {
  40: { name: 'Human', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Bravely Default' },
  45: { name: 'Aventi Human', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Water Breathe, Swim Speed' },
  50: { name: 'Death Touched Human', str: 0, dex: 0, con: -2, ac: 0, lvl: 1, notes: 'Darkvision, Cold/Elec resist 5, Cold Hands' },
  55: { name: 'Deep Imarski Human', str: 0, dex: -2, con: 0, ac: 0, lvl: 0, notes: 'Spell Clutch' },
  70: { name: 'Illumian', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Sigils, word powers' },
  75: { name: 'Mongrelfolk', str: 0, dex: 0, con: 4, ac: 0, lvl: 0, notes: 'Patchwork People' },
  80: { name: 'Neanderthal', str: 2, dex: -2, con: 2, ac: 0, lvl: 0, notes: 'Primitive Weapons, climate tolerators' },
  85: { name: 'Sea Kin', str: 0, dex: 0, con: 4, ac: 0, lvl: 0, notes: 'Water Dependent, Trident and Net, Swim Speed' },
  90: { name: 'Sharakim', str: 2, dex: -2, con: 0, ac: 1, lvl: 0, notes: 'Shadow Bois' },
  95: { name: 'Skulk', str: 0, dex: 4, con: 0, ac: 0, lvl: 1, notes: 'VERY sneak' },
  99: { name: 'Underfolk', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Picky, Camoflage' },
  100: { name: 'Roll 2 and pick' }
}

const scalykindTable = {
  40: { name: 'Tunnel Kobold', str: -4, dex: 2, con: -2, ac: 1, lvl: 0, notes: 'Dragonblood, suck' },
  44: { name: 'Aquatic Kobold', str: -4, dex: 2, con: -2, ac: 1, lvl: 0, notes: 'Breathe Water, Swim Speed' },
  48: { name: 'Arctic Kobold', str: -2, dex: 2, con: -2, ac: 1, lvl: 0, notes: 'Cold Endurance' },
  52: { name: 'Desert Kobold', str: -4, dex: 2, con: 0, ac: 1, lvl: 0, notes: 'Survivier' },
  56: { name: 'Earth Kobold', str: -2, dex: 2, con: -2, ac: 1, lvl: 0, notes: 'Stable' },
  60: { name: 'Jungle Kobold', str: -4, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'High ALtitude?' },
  70: { name: 'Lizardfolk', str: 2, dex: 0, con: 2, ac: 5, lvl: 1, notes: 'Hold Breath' },
  73: { name: 'Poisondusk Lizardfolk', str: 0, dex: 2, con: 2, ac: 3, lvl: 1, notes: 'Poisoner, Hidey' },
  75: { name: 'Phaerlock', str: 4, dex: 0, con: 4, ac: 7, lvl: 2, notes: 'Too many eyes, very pain' },
  85: { name: 'Spellscale', str: 0, dex: 0, con: -2, ac: 0, lvl: 0, notes: 'Magical, Dragonblood' },
  99: { name: 'Troglodyte', str: 0, dex: -2, con: 4, ac: 4, lvl: 2, notes: 'Multiattack, Smelly' },
  100: { name: 'Roll 2 and pick' }
}

const orcTable = {
  75: { name: 'Plains', str: 4, dex: 0, con: 0, ac: 0, lvl: 0, notes: undefined },
  80: { name: 'Aquatic', str: 4, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Waterbreathe, Swim Speed' },
  85: { name: 'Arctic', str: 4, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Cold Endurance, Handle Animal' },
  90: { name: 'Desert', str: 4, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Heat Endurance, Normal Endurance' },
  95: { name: 'Jungle', str: 4, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Heal, Survival' },
  99: { name: 'Water', str: 4, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Swim Speed, bad at fire' },
  100: { name: 'Roll 2 and pick' }
}

const shifterTable = {
  10: { name: 'Beasthide', str: 0, dex: 0, con: 2, ac: 2, lvl: 0, notes: undefined },
  20: { name: 'Cliffwalk', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Climby' },
  30: { name: 'Dreamsight', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: undefined },
  40: { name: 'Gorebrute', str: 2, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Chargey' },
  50: { name: 'Longstride', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Speedy' },
  60: { name: 'Longtooth', str: 2, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Bitey' },
  70: { name: 'Razorclaw', str: 2, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Clawy' },
  80: { name: 'Swiftwing', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Flyish' },
  90: { name: 'Truedive', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Swimmy' },
  100: { name: 'Wildhunt', str: 0, dex: 0, con: 2, ac: 0, lvl: 0, notes: 'Smelly' }
}

const miscTable = {
  7: { name: 'Asherati', str: 0, dex: 0, con: 0, ac: 1, lvl: 0, notes: 'Love Sand, Hate Water, Glowy' },
  14: { name: 'Catfolk', str: 0, dex: 4, con: 0, ac: 1, lvl: 1, notes: undefined },
  21: { name: 'Changeling', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Change Shape, Skills' },
  27: { name: 'Crucian', str: 4, dex: -2, con: 6, ac: 8, lvl: 2, notes: undefined },
  34: { name: 'Darfellan', str: 2, dex: -2, con: 0, ac: 0, lvl: 0, notes: 'Echolocation, swim speed, hold breath' },
  41: { name: 'Githyanki', str: 0, dex: 2, con: 2, ac: 0, lvl: 2, notes: 'SR, Psionics' },
  48: { name: 'Githzerai', str: 0, dex: 6, con: 0, ac: 0, lvl: 2, notes: 'SR, Psionics' },
  54: { name: 'Golmoid', str: 2, dex: -4, con: 2, ac: 2, lvl: 1, notes: 'Bonus vs Ench' },
  61: { name: 'Grippli', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Jumpy, Hate Animals, Less Poison' },
  67: { name: 'Hadozee', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Gliding' },
  74: { name: 'Kenku', str: -2, dex: 2, con: 0, ac: 0, lvl: 0, notes: 'Great Ally, Mimicry' },
  80: { name: 'Phanaton', str: -2, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Sneaky, Woodsy, Glidey' },
  87: { name: 'Raptoran', str: 0, dex: 0, con: 0, ac: 0, lvl: 0, notes: 'Glide then Flight, Jumpy, Know North' },
  94: { name: 'Shifter', str: 0, dex: 2, con: 0, ac: 0, lvl: 0, table: hydrateTable(shifterTable) },
  99: { name: 'Xvart', str: -2, dex: 2, con: -2, ac: 0, lvl: 0, notes: 'Darkvision' },
  100: { name: 'Roll 2 and pick' }
}

// Base race Table
export const raceTableOne = hydrateTable({
  12: { name: 'Dwarf', table: hydrateTable(dwarfTable) },
  24: { name: 'Elf', table: hydrateTable(elfTable) },
  25: { name: 'Gnoll', table: hydrateTable(gnollTable) },
  37: { name: 'Gnome', table: hydrateTable(gnomeTable) },
  41: { name: '', table: hydrateTable(goblinoidTable) },
  51: { name: 'Half-Elf', table: hydrateTable(halfElfTable) },
  61: { name: 'Half-Orc', table: hydrateTable(halfOrcTable) },
  73: { name: 'Halfling', table: hydrateTable(halflingTable) },
  88: { name: '', table: hydrateTable(humanTable) },
  93: { name: '', table: hydrateTable(scalykindTable) },
  97: { name: 'Orc', table: hydrateTable(orcTable) },
  99: { name: '', table: hydrateTable(miscTable) },
  100: 'Other'
})
