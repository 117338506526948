import React from 'react'
import { useNavigate, Outlet } from 'react-router'
import { DND_PATH, NPC_35_PATH, NPC_GENERATOR, PUZZLE_PATH } from '../../data/constants'

const DnD = () => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        <div className='content-list'>
          <h3>DnD Tools</h3>
          <div>
            <div className='dnd-tool' onClick={() => { navigate(NPC_35_PATH) }}>
              <h4>{NPC_GENERATOR}</h4>
              <p>Random Race/Class and more for NPCs! (3.5e version)</p>
            </div>
            <div className='dnd-tool' onClick={() => { navigate(PUZZLE_PATH)}}>
              <h4>Puzzle of the Day</h4>
              <p>What puzzle will it be? Who knows!</p>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default DnD
