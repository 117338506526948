import Navigation from './components/Navigation'
import './App.css'
import MainView from './components/MainView'
import { BrowserRouter } from 'react-router-dom'

const App = () => {
  return (
    <BrowserRouter>
      <div className='App'>
        <Navigation />
        <p className='App-intro'>
          Welcome to Flynn's Space!
            </p>
        <MainView />
      </div>
    </BrowserRouter>
  )
}

export default App
