import { MINECRAFT_PATH, DND_PATH, FIRST_SERVER, SECOND_SERVER, THIRD_SERVER, NPC_35_PATH, PUZZLE_PATH } from '../data/constants'
import Minecraft from './minecraft/Minecraft'
import DnD from './DnD/DnD'
import Home from './Home'
import { Route, Routes } from 'react-router-dom'
import Firbolg from './minecraft/Firbolg'
import Girallon from './minecraft/Girallon'
import Hecatoncheires from './minecraft/Hecatoncheires'
import GenerateNPC35 from './DnD/GenerateNPC35'
import PuzzleBoi from './DnD/PuzzleBoi'

const MainView = () => {
  // TODO: There has GOT to be a way to nest these routes...
  // Update: nesting routes does NOT have the intended effect, it causes both the parent and children elements to render at the same time.
  // At least the matching is better.
  const router = (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path={MINECRAFT_PATH} element={<Minecraft />} />
        <Route path={MINECRAFT_PATH+'/'+FIRST_SERVER} element={<Firbolg />} />
        <Route path={MINECRAFT_PATH+'/'+SECOND_SERVER} element={<Girallon />} />
        <Route path={MINECRAFT_PATH+'/'+THIRD_SERVER} element={<Hecatoncheires />} />
        <Route path={DND_PATH} element={<DnD />} />
        <Route path={DND_PATH+'/'+NPC_35_PATH} element={<GenerateNPC35 />} />
        <Route path={DND_PATH+'/'+PUZZLE_PATH} element={<PuzzleBoi />} />
      </Routes>
    </>
  )
  return router
}

export default MainView
