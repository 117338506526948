import React, { useState } from 'react'
import { REINCARNATE_35, NPC_GENERATE_35 } from '../../data/constants'
import { raceTableOne } from '../../data/races'
import { classTableOne } from '../../data/classesThreeFive'

const styles = {
    resultContainer: {
        textAlign: 'left'
    }
}

// TODO: Add ability to choose the base race table to roll on
// TODO: Add ability to choose the base class table to roll on
// TODO: Actually add quirks
const GenerateNPC35 = () => {
    const [generateState, setGenerateState] = useState('choose_purpose')
    const [result, setResult] = useState({ generatedRace: null, generatedClass: null })
    const [purpose, setPurpose] = useState(null)

    const randomize = () => {
        setGenerateState('results')
        const raceInfo = generateRace()
        setResult({ generatedRace: raceInfo, generatedClass: null })
        if (purpose === NPC_GENERATE_35) {
            const classInfo = generateClass()
            setResult({ generatedRace: raceInfo, generatedClass: classInfo })
        }
    }

    const handleOptionChange = changeEvent => {
        setPurpose(changeEvent.target.value)
    }

    let content = null
    if (generateState === 'choose_purpose') {
        content = (
            <>
                <p>Choose purpose:</p>
                <div>
                    <input type='radio' id='reincarnate' name='type' value={REINCARNATE_35} onChange={handleOptionChange} />
                    <label htmlFor='reincarnate'>Reincarnate</label>
                </div>
                <div>
                    <input type='radio' id='NPC' name='type' value={NPC_GENERATE_35} onChange={handleOptionChange} />
                    <label htmlFor='NPC'>NPC Generation</label>
                </div>
                <button onClick={randomize}>Get Random Boi!</button>
            </>
        )
    } else {
        content = (
            <div className="result container">
                <RaceResult race={result.generatedRace} />
                <ClassResult classResult={result.generatedClass} />
                <button onClick={() => { setGenerateState('choose_purpose') }}>Start Over</button>
                <button onClick={randomize}>Again?</button>
            </div>
        )
    }

    return (
        <div>
            <div className="npc-generate" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {content}
            </div>
        </div>
    )
}

export default GenerateNPC35

const percentile = () => {
    return Math.floor(Math.random() * 100)
}

const generateRace = () => {
    const raceTableOneFull = raceTableOne
    const raceRoll1 = percentile()
    let result = raceTableOneFull[raceRoll1]
    let finalResult = { name: result.name }
    while (result.table) {
        const nextRaceRoll = percentile()
        result = result.table[nextRaceRoll]
        if (result.name) { finalResult.name = (finalResult.name ? finalResult.name + ', ' : '') + result.name }
        if (!result.table) {
            delete result.name
            finalResult = { name: finalResult.name, ...result }
        }
    }

    return finalResult
}

const generateClass = () => {
    const classTableOneFull = classTableOne
    const classRoll1 = percentile()
    const result = classTableOneFull[classRoll1]
    // No nested tables here, just ever the first/second
    const nextClassRoll = percentile()
    const finalResult = result.table[nextClassRoll]

    return finalResult
}

const RaceResult = ({ race }) => {
    if (!race) return null
    return (
        <div className='reincarnate result' style={styles.resultContainer}>
            <h2>{race.name} {race.super ? race.super : ''}</h2>
            <ul>
                {statLineItem(race.str, 'Strength')}
                {statLineItem(race.dex, 'Dexterity')}
                {statLineItem(race.con, 'Constitution')}
                {statLineItem(race.ac, 'Natural Armor')}
                {statLineItem(race.lvl, 'Level Adjustment')}
                {statLineItem(race.notes, 'Notes')}
            </ul>
        </div>
    )
}

const ClassResult = ({ classResult }) => {
    if (!classResult) return null
    const features = classResult.features.split(',').map((f, i) => <li key={i}>{f}</li>)
    const gender = percentile() > 50 ? 'Female' : 'Male'
    return (
        <div className='class result' style={styles.resultContainer}>
            <h2>{gender} {classResult.name} ({classResult.notes})</h2>
            <ul>{features}</ul>
        </div>
    )
}

const statLineItem = (val, name) => {
    // if this isn't a number, and isn't empty
    if (isNaN(parseInt(val)) && val !== undefined) {
        return <li>{name}: {val}</li>
    } else if (val > 0) {
        return <li>{name}: +{val}</li>
    } else if (val < 0) {
        return <li>{name}: {val}</li>
    } else {
        return null
    }
}
