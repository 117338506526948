import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'

const Home = () => {
  const threeCanvas = useRef()
  const [rotVector, setRotVector] = useState(new THREE.Vector2(0.01, 0.01))
  const [prevMousePos, setPrevMousePos] = useState({ x: null, y: null })

  const handleMove = (evt) => {
    const moveVector = new THREE.Vector2(evt.clientX - prevMousePos.x, evt.clientY - prevMousePos.y).normalize()
    setRotVector(moveVector.multiplyScalar(0.01))
  }

  useEffect(() => {
    // === THREE.JS CODE START ===
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(window.innerWidth * 0.9, window.innerHeight * 0.9)
    threeCanvas.current.appendChild(renderer.domElement)
    const objLoader = new FBXLoader()
    const texLoader = new THREE.TextureLoader()
    let d20 = null
    const settings = {
      metalness: 1.0,
      roughness: 0.4,
      ambientIntensity: 0.2,
      aoMapIntensity: 1.0,
      envMapIntensity: 1.0,
      displacementScale: 2.436143, // from original model
      normalScale: 1.0
    }
    const normalMap = texLoader.load('/media/d20/textures/Scratchpaint_Normal_4k.jpg')
    const aoMap = texLoader.load('/media/d20/textures/Scratchpaint_AO_2k.png')
    const material = new THREE.MeshStandardMaterial({

      color: 0x888888,
      roughness: settings.roughness,
      metalness: settings.metalness,

      normalMap: normalMap,
      normalScale: new THREE.Vector2(1, - 1), // why does the normal map require negation in this case?

      aoMap: aoMap,
      aoMapIntensity: 1,

      side: THREE.DoubleSide

    })
    objLoader.load('/media/d20/source/D20_new_test.fbx', (obj) => {
      const geometry = obj.children[0].geometry;
      geometry.center();

      const mesh = new THREE.Mesh(geometry, material);

      scene.add(mesh);
      d20 = mesh
    })

    const pointLight3 = new THREE.PointLight(0xaa0000, 0.75);
    pointLight3.position.x = -4;
    pointLight3.position.z = 4;
    scene.add(pointLight3);

    camera.position.z = 2.5
    const animate = function () {
      requestAnimationFrame(animate)
      if (d20) {
        d20.rotation.x += rotVector.x
        d20.rotation.y += rotVector.y
      }
      renderer.render(scene, camera)
    }
    animate()
    // === THREE.JS EXAMPLE CODE END ===
  }, [])
  return (
    <div
      ref={threeCanvas}
      onMouseEnter={(evt) => { setPrevMousePos({ x: evt.clientX, y: evt.clientY }) }}
      onMouseMove={handleMove}
    />
  )
}

export default Home
