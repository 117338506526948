import React from 'react'
import { useNavigate, Outlet } from 'react-router'
import { FIRST_SERVER, MINECRAFT_PATH, SECOND_SERVER, THIRD_SERVER } from '../../data/constants'

const Minecraft = () => {
  const navigate = useNavigate()

  return (
    <>
      <div>
        <div className="content-list">
          <h3>Minecraft Maps</h3>
          <p>Using the Dynmap plugin for Spigot, static maps of past worlds have been generated, and an up to date and active one for the current server hosted here is maintained! Take a walk through yesteryear's server, admire your fellow crafters work, and wonder "why a giant sheep vomiting pumpkins..."</p>
          <p>An explanation: The server "Codenames" are DnD monsters that I find particularly delightful, starting with consecutive letters of the alphabet. Before Spigot 1.12.2, I believe I ran approximately 5 other servers; hence, beginning with F. (The monsters are unrelated to any quality of the server, I just happen to love these guys.)</p>
          <div className='server-item' onClick={() => {navigate(FIRST_SERVER)}}>
            <h4>Codename Firbolg: 27 November, 2017 - 09 August, 2018.</h4>
            <p>The first server on the new hardware, following catastrophic hardware failure of the previous machine, a new server was forged! Marked by a multi-tier iron farm, a wonderful spawn village, and the first time playing for half the users, it began a new era of Minecraft players.</p>
            <p>Minecraft Versions: Spigot 1.12.2</p>
            <p>The Mighty Firbolg: While once a mighty, ginger Giant, the Firbolg has taken a gentler and much more endearing bent in later editions of DnD. Based on Irish mythology, they love nature and are larger than life.</p>
          </div>
          <div className='server-item' onClick={() => {navigate(SECOND_SERVER)}}>
            <h4>Codename Girallon: 09 August, 2018 - 02 July, 2020</h4>
            <p>The longest lived server I've run, it saw many people come and go; characterized by the ice boat highway, much End exploration, and the endearing "Monument to Professionalism" erected by an early settler. It was also unique in that it was one of very few servers I've ever upgraded the version on, and changed from Vanilla to Spigot.</p>
            <p>Minecraft Versions: Vanilla 1.13.2, Spigot 1.13.2, Spigot 1.14.4</p>
            <p>The Fierce Girallon: As the years come and go, many monsters have seen their time come and go; curiously enough, the Girallon (which is literally a four armed Gorilla) has endured. In addition to being a DnD staple, they also have had a minor appearance in the Beyblade Anime...</p>
          </div>
          <div className='server-item' onClick={() => {navigate(THIRD_SERVER)}}>
            <h4>Codename Hecatoncheires: 02 July, 2020 - THE FUTURE</h4>
            <p>Planned to be a long term support server, the plan is to upgrade this for multiple years to come. Featuring a collaborative spawn area, a marketplace, and some delightfully aesthetic farms, the future is bright! </p>
            <p>Minecraft Versions: Spigot 1.16.1, Spigot 1.16.5</p>
            <p>The Horrendous Hecatoncheires: A greek mythological creature brought to horrifying life by DnD, it has fifty heads, one hundred arms, and is kept imprisoned so that even the gods are safe from their rage and might...the author has a particular fondness for the memory of summoning a Hecatoncheires in DnD 3.5 and giving it obscene bard buffs, leading to a true abomination of player agency.</p>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default Minecraft
