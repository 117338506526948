export const INDEX_VIEW = 'Home'
export const MINECRAFT_VIEW = 'Minecraft Maps'
export const MINECRAFT_PATH = '/Minecraft'
export const DND_VIEW = 'DnD Tools'
export const DND_PATH = '/DnD'
export const FIRST_SERVER = 'Firbolg'
export const SECOND_SERVER = 'Girallon'
export const THIRD_SERVER = 'Hecatoncheires'
export const NPC_GENERATOR = 'NPC Generator'
export const NPC_35_PATH = 'GenerateNpc35'
export const NPC_GENERATOR_35 = 'NPC Generator (3.5e)'
export const NPC_GENERATOR_5 = 'NPC Generator (5e)'
export const BATTLE_MANAGER = 'Battle Manager'
export const REINCARNATE_35 = 'Reincarnate (3.5)'
export const NPC_GENERATE_35 = 'Generate NPC (3.5)'
export const PUZZLE_PATH = 'puzzle'
