import { hydrateTable } from './helper'

const frontlineTable = {
  10: { name: 'Barbarian', features: 'Rage, Skills', notes: 'Core, DPS' },
  40: { name: 'Fighter', features: 'Bonus feats, Unique feats, Feets feats', notes: 'Core, flexible if DPS/Tank' },
  50: { name: 'Knight', features: 'Crowd Control, Survivability', notes: 'Semi-core, Tank' },
  60: { name: 'Monk', features: 'Fisticuffs, Skills, Random defense/bonus feats', notes: 'Core, Better tank/control than DPS' },
  70: { name: 'Paladin', features: 'Holy, Spells, Mount, Stick up Butt', notes: 'Core, conditional DPS' },
  85: { name: 'Ranger', features: 'Weapon styles, Racism, Spells, Skills', notes: 'Core, conditional DPS' },
  100: { name: 'Samurai', features: 'Weapon style, Shouting, Honor', notes: 'Asian core, DPS' }
}

const skillsTable = {
  5: { name: 'Artificer', features: 'Item creation, UMD, Traps, Magic', notes: 'Eberron, Jack of All Trades' },
  30: { name: 'Bard', features: 'Buffs, Social Skills, Spells, Knowledge', notes: 'Core, Memetastic' },
  40: { name: 'Beguiler', features: 'Traps, Sneaky, Sneak Attack, Surprise Spells', notes: 'Semi-core, Full Caster' },
  55: { name: 'Ninja', features: 'Traps, Sneaky, Sneak Attack, Ghost powers', notes: 'Asian core' },
  85: { name: 'Rogue', features: 'Traps, Sneak Attack, Super Skills', notes: 'Core' },
  100: { name: 'Scout', features: 'Traps, Skirmish, Whatever popped into designers head', notes: 'Supplemental, Action economy risk' }
}

const divineTable = {
  5: { name: 'Archivist', features: 'Knowledge, Int Based Prepared Casting, Expanded Spell List, Buffs', notes: 'Supplemental, Spooky' },
  35: { name: 'Cleric', features: 'Deific focus, Undead stuff, Good AC, Good Heals', notes: 'Core, Flexible' },
  55: { name: 'Druid', features: 'Wildshape, Nature Spells, Animal Bro, No Metal', notes: 'Core, Not ideal primary healer' },
  70: { name: 'Healer', features: 'Skills, Animal Companion, Mostly onlyheals', notes: 'Supplemental' },
  90: { name: 'Favored Soul', features: 'Spontaneous Cleric, Weird deific benefits, Two ability scores', notes: 'Supplemental' },
  95: { name: 'Shugenja', features: 'Spontaneous "Cleric" Casting, Expanded Spell List, Strange Elemental Abilities', notes: 'Asian Core, The worst' },
  100: { name: 'Spirit Shaman', features: 'Spontaneous Druid Casting, Spirit stuff, Weird Proficiencies', notes: 'Supplemental' }
}

const arcaneTable = {
  10: { name: 'Beguiler', features: 'Skills, Sneaky, Spontaneous, Illusion/Ench focused', notes: 'Semi-core' },
  40: { name: 'Sorcerer', features: 'Spontaneous, Familiar, Draconic options', notes: 'Core' },
  50: { name: 'Warmage', features: 'Spontaneous, Evoc/Conj focused, Extra Damage, Armored Casting', notes: 'Supplemental' },
  90: { name: 'Wizard', features: 'Prepared, Familiar, Knowledge', notes: 'Core' },
  100: { name: 'Wu Jen', features: 'Prepared, Unique Spells, Elemental focus', notes: 'Asian Core' }
}

const auxiliaryTable = {
  10: { name: 'Artificer', features: 'Traps, Skills, Magic Items, Not really spells', notes: 'Eberron, Jack of all Trades' },
  40: { name: 'Bard', features: 'Buffs, Social Skills, Spells, Knowledge', notes: 'Core' },
  45: { name: 'Dread Necromancer', features: 'Full Spellcasting, Spontaneous, You become undead', notes: 'Supplemental, Not very versatile' },
  55: { name: 'Marshal', features: 'Extraordinary Buffs, Ally helping, Social Skills', notes: 'Supplemental, Not great progression' },
  70: { name: 'Monk', features: 'Punching stuff, Mystical abilities, High touch AC', notes: 'Core, Can be hard as main' },
  100: { name: 'Warlock', features: 'Spooky, Unlimited small spells, Eldritch blast, random UMD', notes: 'Supplemental, Not very versatile' }
}

const unusualTable = {
  20: { name: 'Binder', features: 'Bind vestiges which grant many abilities some deem...unnatural', notes: 'Supplemental, Too flexible' },
  40: { name: 'Duskblade', features: 'Weapon Spellcasting', notes: 'Semi-core, Not great at spells nor attacking' },
  60: { name: 'Dragon Shaman', features: 'Aura buffs, Breath weapon related abilities, best out of combat healing', notes: 'Semi-core' },
  80: { name: 'Hexblade', features: 'Curses, Weapons, Mettle', notes: 'Supplemental, Like a Paladin but cursier' },
  100: { name: 'Spellthief', features: 'Steals spells, Sneak attack, Handles traps, Casts spells', notes: 'Supplemental' }
}

// Base class table
export const classTableOne = hydrateTable({
  20: { name: 'Frontline Fighter', table: hydrateTable(frontlineTable) },
  40: { name: 'Skills', table: hydrateTable(skillsTable) },
  60: { name: 'Divine Caster', table: hydrateTable(divineTable) },
  80: { name: 'Arcane Caster', table: hydrateTable(arcaneTable) },
  95: { name: 'Auxilliary', table: hydrateTable(auxiliaryTable) },
  100: { name: 'Unusual', table: hydrateTable(unusualTable) }
})
