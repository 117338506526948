export const hydrateTable = (obj) => {
  const tupleBounds = Object.keys(obj)
  let currentTupleIndex = 0
  let currentTuple = tupleBounds[currentTupleIndex]
  const result = []
  for (let i = 1; i <= 100; i++) {
    if (i <= parseInt(currentTuple)) {
      result.push(obj[currentTuple])
    } else {
      currentTupleIndex++
      currentTuple = tupleBounds[currentTupleIndex]
      result.push(obj[currentTuple])
    }
  }
  return result
}
