import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useState } from 'react'

const getColorFromGems = (gems, doubleGems) => {
  let red = 0
  let blue = 0
  let green = 0

  for (const gem of gems) {
    switch (gem?.color) {
      case 'red':
        red++
        break
      case 'blue':
        blue++
        break
      case 'green':
        green++
        break
      default:
    }
  }
  for (const gem of doubleGems) {
    switch (gem?.color) {
      case 'red':
        red += 2
        break
      case 'blue':
        blue += 2
        break
      case 'green':
        green += 2
        break
      default:
    }
  }
  const redString = (Math.floor(255 * (9 - red) / 9)).toString(16).padStart(2, '0')
  const blueString = (Math.floor(255 * (9 - blue) / 9)).toString(16).padStart(2, '0')
  const greenString = (Math.floor(255 * (9 - green) / 9)).toString(16).padStart(2, '0')
  return '#' + redString + greenString + blueString
}

const getBrightnessFromGems = (gems, doubleGems) => {
  let brightness = 0
  for (const gem of gems) {
    switch (gem?.color) {
      case 'red':
        brightness += 1
        break
      case 'green':
        brightness += 2
        break
      case 'blue':
        brightness += 3
        break
      default:
    }
  }
  for (const gem of doubleGems) {
    switch (gem?.color) {
      case 'red':
        brightness += 2
        break
      case 'green':
        brightness += 4
        break
      case 'blue':
        brightness += 6
        break
      default:
    }
  }
  return brightness
}

const goalColor = '#8d8de2'

const PuzzleBoi = () => {
  // Gem State
  const [sourceGems, setSourceGems] = useState(boxGems)
  const [leftGems, setLeftGems] = useState(baseLeftGems)
  const [rightGems, setRightGems] = useState(baseRightGems)

  // Hint state
  const [hasPlacedLeft, setHasPlacedLeft] = useState(false)
  const [hasPlacedRight, setHasPlacedRight] = useState(false)
  const [hasPlacedRed, setHasPlacedRed] = useState(false)
  const [hasPlacedGreen, setHasPlacedGreen] = useState(false)
  const [hasPlacedBlue, setHasPlacedBlue] = useState(false)

  // Border color state
  const [topColor, setTopColor] = useState('#FFFFFF')
  const [rightColor, setRightColor] = useState('#FFFFFF')
  const [bottomColor, setBottomColor] = useState('#FFFFFF')
  const [leftColor, setLeftColor] = useState('#FFFFFF')
  const [puzzleColorStyle, setPuzzleColorStyle] = useState({ borderTop: '0.5rem solid #FFFFFF', borderLeft: '0.5rem solid #FFFFFF', borderRight: '0.5rem solid #FFFFFF', borderBottom: '0.5rem solid #FFF' })

  // Eye state
  const [leftBrightness, setLeftBrightNess] = useState(0)
  const [rightBrightness, setRightBrightness] = useState(0)

  const handleDragEnd = (result) => {
    if (!result.destination) return
    let gem = null
    const newSource = JSON.parse(JSON.stringify(sourceGems))
    const newLeft = JSON.parse(JSON.stringify(leftGems))
    const newRight = JSON.parse(JSON.stringify(rightGems))

    const sourceZone = result.source.droppableId.split('-')[0]
    switch (sourceZone) {
      case 'left':
        gem = newLeft[result.source.droppableId]
        newLeft[result.source.droppableId] = null
        break
      case 'right':
        gem = newRight[result.source.droppableId]
        newRight[result.source.droppableId] = null
        break
      default:
        [gem] = newSource.splice(result.source.index, 1)
        break
    }
    switch (gem.color) {
      case 'red':
        setHasPlacedRed(true)
        break
      case 'green':
        setHasPlacedGreen(true)
        break
      case 'blue':
        setHasPlacedBlue(true)
        break
      default:
    }
    const targetZone = result.destination.droppableId.split('-')[0]
    let yeetGem = null
    switch (targetZone) {
      case 'left':
        yeetGem = newLeft[result.destination.droppableId]
        newLeft[result.destination.droppableId] = gem
        setHasPlacedLeft(true)
        break
      case 'right':
        yeetGem = newRight[result.destination.droppableId]
        newRight[result.destination.droppableId] = gem
        setHasPlacedRight(true)
        break
      default:
        newSource.splice(result.destination.index, 0, gem)
    }
    if (yeetGem) {
      newSource.splice(0, 0, yeetGem)
    }
    const newTopColor = getColorFromGems([newLeft['left-1'], newLeft['left-2'], newLeft['left-3']], [newRight['right-1'], newRight['right-2'], newRight['right-3']])
    const newRightColor = getColorFromGems([newLeft['left-3'], newLeft['left-6'], newLeft['left-9']], [newRight['right-3'], newRight['right-6'], newRight['right-9']])
    const newBottomColor = getColorFromGems([newLeft['left-7'], newLeft['left-8'], newLeft['left-9']], [newRight['right-7'], newRight['right-8'], newRight['right-9']])
    const newLeftColor = getColorFromGems([newLeft['left-1'], newLeft['left-4'], newLeft['left-7']], [newRight['right-1'], newRight['right-4'], newRight['right-7']])

    const topBorder = `0.5rem solid ${newTopColor}`
    const rightBorder = `0.5rem solid ${newRightColor}`
    const bottomBorder = `0.5rem solid ${newBottomColor}`
    const leftBorder = `0.5rem solid ${newLeftColor}`

    const newLeftEye = getBrightnessFromGems([newLeft['left-1'], newLeft['left-2'], newLeft['left-3'], newLeft['left-4'], newLeft['left-6'], newLeft['left-7'], newLeft['left-8'], newLeft['left-9']], [])
    const newRightEye = getBrightnessFromGems([], [newRight['right-1'], newRight['right-2'], newRight['right-3'], newRight['right-4'], newRight['right-6'], newRight['right-7'], newRight['right-8'], newRight['right-9']])

    setLeftGems(newLeft)
    setRightGems(newRight)
    setSourceGems(newSource)
    
    setTopColor(newTopColor)
    setRightColor(newRightColor)
    setBottomColor(newBottomColor)
    setLeftColor(newLeftColor)
    setPuzzleColorStyle({ borderTop: topBorder, borderRight: rightBorder, borderBottom: bottomBorder, borderLeft: leftBorder })

    setLeftBrightNess(newLeftEye)
    setRightBrightness(newRightEye)
  }

  const rightContent = []
  for (const key of Object.keys(rightGems)) {
    const val = rightGems[key]
    if (val !== 'placeholder') {
      rightContent.push(<Droppable droppableId={key}>
        {provided => (
          <div className='gem-receptacle' {...provided.droppableProps} ref={provided.innerRef}>
            {val ? (<Draggable key={`${val.id}`} draggableId={`${val.id}`} index={0}>
              {provided => (
                <div ref={provided.innerRef} className={`gem ${val.color}`} {...provided.draggableProps} {...provided.dragHandleProps} />
              )}
            </Draggable>) : undefined}
            {provided.placeholder}
          </div>
        )}
      </Droppable>)
    } else {
      rightContent.push(<div className='gem-placeholder' />)
    }
  }

  const leftContent = []
  for (const key of Object.keys(leftGems)) {
    const val = leftGems[key]
    if (val !== 'placeholder') {
      leftContent.push(<Droppable droppableId={key}>
        {provided => (
          <div className='gem-receptacle' {...provided.droppableProps} ref={provided.innerRef}>
            {val ? (<Draggable key={`${val.id}`} draggableId={`${val.id}`} index={0}>
              {provided => (
                <div ref={provided.innerRef} className={`gem ${val.color}`} {...provided.draggableProps} {...provided.dragHandleProps} />
              )}
            </Draggable>) : undefined}
            {provided.placeholder}
          </div>
        )}
      </Droppable>)
    } else {
      leftContent.push(<div className='gem-placeholder' />)
    }
  }

  let matchContent = null
  if (topColor === rightColor && rightColor === bottomColor && bottomColor === leftColor) {
    if (topColor === goalColor) {
      matchContent = (<div>The inner square color matches the outer square color, perhaps the eyes need to match brightness?</div>)
    } else if (topColor !== '#FFFFFF' && topColor !== '#ffffff') {
      matchContent = (<div>The color of all four sides of the inner square match.</div>)
    }
  } else {
    matchContent = []
    if (topColor === goalColor) {
      matchContent.push((<div>The color of the top of the inner square matches the outer square's color.</div>))
    }
    if (rightColor === goalColor) {
      matchContent.push((<div>The color of the right side of the inner square matches the outer square's color.</div>))
    }
    if (bottomColor === goalColor) {
      matchContent.push((<div>The color of the bottom of the inner square matches the outer square's color.</div>))
    }
    if (leftColor === goalColor) {
      matchContent.push((<div>The color of the left side of the inner square matches the outer square's color.</div>))
    }
  }

  let brightnessContent = null
  if (hasPlacedRed) {
    if (hasPlacedGreen) {
      if (hasPlacedBlue) {
        brightnessContent = (<div>Hint: Blue gems seem to have a greater effect on eye brightness than green gems, which in turn have more of an effect than red gems.</div>)
      } else {
        brightnessContent = (<div>Hint: Green gems seem to have a greater effect on eye brightness than red gems.</div>)
      }
    } else {
      if (hasPlacedBlue) {
        brightnessContent = (<div>Hint: Blue gems seem to have a much greater effect on eye brightness than red gems.</div>)
      }
    }
  } else if (hasPlacedGreen && hasPlacedBlue) {
    brightnessContent = (<div>Hint: Blue gems seem to have a greater effect on eye brightness than green gems.</div>)
  }

  let victoryContent = null
  if (topColor === rightColor && rightColor === bottomColor && bottomColor === leftColor && topColor.toLowerCase() !== '#ffffff' && leftBrightness === rightBrightness) {
    victoryContent = (<h2>A bright, coruscating beam of lavender energy strikes the wall of force, destroying it in a flash of cool blue light!</h2>)
  }

  return (
    <div style={{padding: '5%'}}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='puzzle-drop-zone'>
          <div className='side-container'>
            Left Eye
            <div className='gem' style={{ border: '1px solid white', backgroundColor: `hsl(0, 0%, ${100 * leftBrightness / 48}%)` }} />
            Left Hand
            <div className='hand-box'>
              <div className='puzzle-color' style={puzzleColorStyle}>
                {leftContent}
              </div>
            </div>
          </div>
          <div>
            Storage Box
            <Droppable droppableId='source'>
              {provided => (
                <div className='storage-box' {...provided.droppableProps} ref={provided.innerRef}>
                  {sourceGems.map((val, index) => (
                    <Draggable key={`${val.id}`} draggableId={`${val.id}`} index={index}>
                      {provided => (
                        <div ref={provided.innerRef} className={`gem ${val.color}`} {...provided.draggableProps} {...provided.dragHandleProps}>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className='side-container'>
            Right Eye
            <div className='gem' style={{ border: '1px solid white', backgroundColor: `hsl(0, 0%, ${100 * rightBrightness / 48}%)` }} />
            Right Hand
            <div className='hand-box'>
              <div className='puzzle-color' style={puzzleColorStyle}>
                {rightContent}
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
      <div>
        <h2>Feedback:</h2>
        {!victoryContent ? (hasPlacedLeft && hasPlacedRight) ? (<div>Hint: Gems placed in the left hand seem to have half the effect of placing gems in the right hand.</div>) : null : null}
        {!victoryContent ? matchContent : null}
        {!victoryContent ? brightnessContent : null}
        {victoryContent}
      </div>
    </div>
  )
}

export default PuzzleBoi

const boxGems = [{
  color: 'red',
  id: 'red-1'
}, {
  color: 'green',
  id: 'green-1'
}, {
  color: 'blue',
  id: 'blue-1'
}, {
  color: 'red',
  id: 'red-2'
}, {
  color: 'green',
  id: 'green-2'
}, {
  color: 'blue',
  id: 'blue-2'
}, {
  color: 'red',
  id: 'red-3'
}, {
  color: 'green',
  id: 'green-3'
}, {
  color: 'blue',
  id: 'blue-3'
}, {
  color: 'red',
  id: 'red-4'
}, {
  color: 'green',
  id: 'green-4'
}, {
  color: 'blue',
  id: 'blue-4'
}, {
  color: 'red',
  id: 'red-5'
}, {
  color: 'green',
  id: 'green-5'
}, {
  color: 'blue',
  id: 'blue-5'
}, {
  color: 'red',
  id: 'red-6'
}, {
  color: 'green',
  id: 'green-6'
}, {
  color: 'blue',
  id: 'blue-6'
}, {
  color: 'red',
  id: 'red-7'
}, {
  color: 'green',
  id: 'green-7'
}, {
  color: 'blue',
  id: 'blue-7'
}, {
  color: 'red',
  id: 'red-8'
}, {
  color: 'green',
  id: 'green-8'
}, {
  color: 'blue',
  id: 'blue-8'
}, {
  color: 'red',
  id: 'red-9'
}, {
  color: 'green',
  id: 'green-9'
}, {
  color: 'blue',
  id: 'blue-9'
}, {
  color: 'red',
  id: 'red-10'
}, {
  color: 'green',
  id: 'green-10'
}, {
  color: 'blue',
  id: 'blue-10'
}]

const baseLeftGems = {
  'left-1': null,
  'left-2': null,
  'left-3': null,
  'left-4': null,
  'left-5': 'placeholder',
  'left-6': null,
  'left-7': null,
  'left-8': null,
  'left-9': null
}

const baseRightGems = {
  'right-1': null,
  'right-2': null,
  'right-3': null,
  'right-4': null,
  'right-5': 'placeholder',
  'right-6': null,
  'right-7': null,
  'right-8': null,
  'right-9': null
}
